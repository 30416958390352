import React from 'react';
import { Redirect } from 'react-router-dom';
import { ThabetUtils } from '@thabet/index';
import { appsConfigs } from 'app/main/apps/appsConfigs';
import { pagesConfigs } from 'app/main/pages/pagesConfigs';

const routeConfigs = [
     ...appsConfigs,
     ...pagesConfigs
];

const routes = [
     ...ThabetUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/landing" />
    },
    {
        component: () => <Redirect to="/pages/errors/error-404" />
    }
];

export default routes;