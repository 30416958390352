import {combineReducers} from 'redux';
import settings from './settings.reducer';
import routes from './routes.reducer';
import navigation from './navigation.reducer';

const thabetReducers = combineReducers({
    settings,
    routes,
    navigation
});

export default thabetReducers;