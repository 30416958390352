import React from 'react';
import Navigation from './Navigation';
import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Headroom from 'react-headroom';

const useStyles = makeStyles(theme => ({
    mobileLogo: {
        height: '4.4rem'
    },
    headroom: {
        height: '100% !important',
        backgroundColor: '#fff !important',
        '& .headroom': {
            backgroundColor: '#fff !important'
        },
        '& .headroom--unpinned': {
            transform: 'translateY(-200%) !important'
        }
    }
}));

const Header = (props) => {
    const classes = useStyles(props);
    return (
        <Headroom className={classes.headroom}>
            <div id="header" className="flex items-center justify-end flex-wrap p-2 border-b-2 border-thabet-500">
                <Hidden mdUp>
                    <img className={clsx("absolute ml-0 left-0 top-0", classes.mobileLogo)}
                        src="assets/images/logo.png"
                        alt="Thabet Logo" />
                </Hidden>
                <Hidden mdDown>
                    <img className="absolute ml-48 left-0 top-0 h-32"
                        src="assets/images/logo.png"
                        alt="Thabet Logo" />
                </Hidden>
                <Navigation dense />
            </div>
        </Headroom>
    );
}

export default Header;
