import React, { useState } from 'react';
import { Grow, Paper, ListItem, ListItemText, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLinkAdapter } from '@thabet';
import { useDebounce } from '@thabet/hooks';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//import {useSelector} from 'react-redux';
import { Manager, Reference, Popper } from 'react-popper';
import * as ReactDOM from 'react-dom';
import NavItem from './NavItem';
import Home from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
    root: {
        '&.active': {
            borderBottom: '1px solid #AE8838',
            color: '#319795 !important',
            '& .list-item-text-primary': {
                color: 'inherit'
            },
            '& .list-item-icon': {
                color: 'inherit'
            }
        },
        color: "#hhh",
        '& .list-item-text': {
            padding: '0 0 0 16px'
        },
        '&.level-0': {
            height: 48,
            borderRadius: 4,
            // '&:hover'   : {
            //     background: 'transparent'
            // },
        },
        '&.dense': {
            padding: '8px 12px 8px 12px',
            minHeight: 40,
            '&.level-0': {
                height: 44
            },
            '& .list-item-text': {
                padding: '0 0 0 0px'
            }
        }
    },
    children: {},
    popper: {
        zIndex: 999
    },
    popperClose: {
        pointerEvents: 'none'
    }
}));

const NavGroup = (props) => {
    const classes = useStyles(props);
    const [opened, setOpened] = useState(false);
    const { item, dense } = props;
    const handleToggle = useDebounce((open) => {
        setOpened(open);
    }, 150);

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div ref={ref} className='mr-1'>
                        <ListItem
                            button
                            className={clsx("list-item ", classes.root, "relative", dense && "dense", (item.path && window.location.href.search(item.path) !== -1) && 'active')}
                            onMouseEnter={() => handleToggle(true)}
                            onMouseLeave={() => handleToggle(false)}
                            aria-owns={opened ? 'menu-list-grow' : null}
                            aria-haspopup="true"
                            to={item.url}
                            component={NavLinkAdapter}
                            activeClassName="active"
                        >
                            <Hidden mdUp>
                                <ListItemText className={classes.textItem} primary={<Home />} classes={{ primary: 'text-lg font-bold font-sans text-thabet-500' }} />

                            </Hidden>
                            <Hidden mdDown>
                                <ListItemText className={classes.textItem} primary={item.title} classes={{ primary: 'text-lg font-bold font-sans text-thabet-500' }} />
                            </Hidden>

                        </ListItem>
                    </div>
                )}
            </Reference>
            {ReactDOM.createPortal(
                <Popper
                    placement="bottom-start"
                    eventsEnabled={opened}
                    positionFixed
                >
                    {({ ref, style, placement, arrowProps }) => (
                        <div
                            ref={ref}
                            style={{
                                ...style,
                                zIndex: 999
                            }}
                            data-placement={placement}
                            className={clsx(classes.popper, { [classes.popperClose]: !opened })}
                        >
                            <Grow in={opened} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                                <Paper
                                    onMouseEnter={() => handleToggle(true)}
                                    onMouseLeave={() => handleToggle(false)}
                                >
                                    {item.children && (
                                        <ul className={clsx(classes.children, "pl-0")}>
                                            {
                                                item.children.map((item) => (
                                                    <React.Fragment key={item.id}>
                                                        <NavItem item={item} dense={dense} />
                                                    </React.Fragment>
                                                ))
                                            }
                                        </ul>
                                    )}
                                </Paper>
                            </Grow>
                        </div>
                    )}
                </Popper>,
                document.querySelector('#root')
            )}
        </Manager>
    );
}

NavGroup.propTypes = {
    item: PropTypes.shape(
        {
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            children: PropTypes.array
        })
};

NavGroup.defaultProps = {};

const NavHorizontalGroup = withRouter(React.memo(NavGroup));

export default NavHorizontalGroup;
