import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { NavLinkAdapter } from '@thabet';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import {useDispatch} from 'react-redux';
// import * as Actions from 'app/store/actions';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 48,
        '&.active': {
            backgroundColor: "#fff",
            color: '#7b5b06 !important',
            pointerEvents: 'none',
            '& .list-item-text': {
                
            },
            '& .list-item-icon': {
                color: 'inherit'
            }
        },
        '& .list-item-icon': {},
        '& .list-item-text': {
            padding: '10px 14px 10px 14px'
        },
        color: "#hhh",
        textDecoration: 'none!important',
        '&:hover': {
            backgroundColor: "#e2b866",
            color: '#7b5b06 !important',
            border: '2px solid #7b5b06'
        }
    }
}));

function NavButton(props) {
    //const dispatch = useDispatch();

    const classes = useStyles(props);
    const { item } = props;

    return (
        <ListItem
            button
            component={NavLinkAdapter}
            to={item.url}
            activeClassName="active"
            className={clsx("list-item bg-thabet-500 rounded-full text-white border-thabet-900 border-solid border-2 h-5", classes.root)}
            //onClick={ev => dispatch(Actions.navbarCloseMobile())}
            exact={item.exact}
        >
            <ListItemText className="list-item-text" primary={item.title} classes={{ primary: 'text-14 list-item-text-primary font-bold' }} />

        </ListItem>
    );
}

NavButton.propTypes = {
    item: PropTypes.shape(
        {
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            icon: PropTypes.string,
            url: PropTypes.string
        })
};

NavButton.defaultProps = {};

export default withRouter(React.memo(NavButton));