import React from 'react';
import PropTypes from 'prop-types';
import {ThabetLoading} from '@thabet';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */function ThabetSuspense(props)
{
    return (
        <React.Suspense fallback={<ThabetLoading {...props.loadingProps} />}>
            {props.children}
        </React.Suspense>
    );
}

ThabetSuspense.propTypes = {
    loadingProps: PropTypes.object,
};

ThabetSuspense.defaultProps = {
    loadingProps: {
        delay: 0
    }
};

export default ThabetSuspense;
