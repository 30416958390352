import React from 'react';
import { Divider, List } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NavGroup from './components/NavGroup';
import NavItem from './components/NavItem';
import NavButton from './components/NavButton';

const ThabetNavigation = (props) => {
    const { navigation, active, dense, className } = props;

    const nav = (
        <List className={clsx("navigation whitespace-no-wrap flex p-0", className)}>
            {
                navigation.map((item) => (

                    <React.Fragment key={item.id}>

                        {item.type === 'group' && (
                            <NavGroup item={item} nestedLevel={0} active={active} dense={dense} />
                        )}

                        {item.type === 'button' && (
                            <NavButton item={item} nestedLevel={0} active={active} dense={dense} />
                        )}

                        {item.type === 'item' && (
                            <NavItem item={item} nestedLevel={0} active={active} dense={dense} />
                        )}

                        {item.type === 'divider' && (
                            <Divider className="my-16" />
                        )}
                    </React.Fragment>
                ))
            }
        </List>
    );

    return nav;
}

ThabetNavigation.propTypes = {
    navigation: PropTypes.array.isRequired
};

export default React.memo(ThabetNavigation);
