
const navigationConfig = [
    // {
    //     'id': 'landing',
    //     'title': 'QUI SOMMES-NOUS',
    //     'type': 'group',
    //     'icon': 'dashboard',
    //     'url': '/landing',
    //     'path':'/landing',
    //     'exact': true,
    //     'children': []
    // },
    // {
    //     'id': 'donation',
    //     'title': 'Faire un Don',
    //     'type': 'button',
    //     'icon': null,
    //     'url': '/don',
    //     'path':'/don',
    //     'exact': true,
    //     'children': []
    // }
];

export default navigationConfig;
