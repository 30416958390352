import React from 'react';

export const LandingAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            exact: true,
            path: '/landing',
            component: React.lazy(() => import('./LandingGlobalApp'))
        }
    ]
}