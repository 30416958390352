//import * as Actions from 'app/store/actions/thabet/index';
const initialState = {
    defaults: {},
    current: {},
};

const settings = function (state = initialState, action) {
    switch (action.type) {
        default:
            {
                return state;
            }
    }
};

export default settings;