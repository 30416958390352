import React from 'react';

const SubHeader = (props) => {
    return (
        <div id="sub-header" className="w-full">
            <img className="w-full"
                src="assets/images/banner.jpg"
                alt="Amana Charity Logo" />
        </div>
    );
}

export default SubHeader;
