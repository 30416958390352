import React from 'react';
import {ListItem, ListItemText} from '@material-ui/core';
import {NavLinkAdapter} from '@thabet';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import {useDispatch} from 'react-redux';
// import * as Actions from 'app/store/actions';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight          : 48,
        '&.active'         : {
            backgroundColor            : "#c1c1c1",
            color                      : '#555 !important',
            pointerEvents              : 'none',
            '& .list-item-text-primary': {
                color: 'inherit'
            },
            '& .list-item-icon'        : {
                color: 'inherit'
            }
        },
        '& .list-item-icon': {},
        '& .list-item-text': {
            padding: '0 0 16px 16px'
        },
        color              : "#hhh",
        textDecoration     : 'none!important',
        '&.dense'          : {
            padding            : '8px 12px 8px 12px',
            minHeight          : 40,
            '& .list-item-text': {
                padding: '0 0 0 0px'
            }
        }
    }
}));

function NavItem(props)
{
    //const dispatch = useDispatch();

    const classes = useStyles(props);
    const {item, dense} = props;

    return (
        <ListItem
            button
            component={NavLinkAdapter}
            to={item.url}
            activeClassName="active"
            className={clsx("list-item", classes.root, dense && "dense")}
            //onClick={ev => dispatch(Actions.navbarCloseMobile())}
            exact={item.exact}
        >
            <ListItemText className="list-item-text" primary={item.title} classes={{primary: 'text-14 list-item-text-primary'}}/>

        </ListItem>
    );
}

NavItem.propTypes = {
    item: PropTypes.shape(
        {
            id   : PropTypes.string.isRequired,
            title: PropTypes.string,
            icon : PropTypes.string,
            url  : PropTypes.string
        })
};

NavItem.defaultProps = {};

export default withRouter(React.memo(NavItem));
