import React from 'react';
import { ThabetSuspense } from '@thabet';
import { renderRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import AppContext from 'app/AppContext';
import Header from './components/Header';
import Footer from './components/Footer';
import SubHeader from './components/SubHeader';

function Layout(props) {
    return (
        <AppContext.Consumer>
            {({ routes }) => (
                <div id="amana-layout" className="w-full">
                    {/* <Header/> */}
                    <SubHeader/>
                    <div id="content" className="flex flex-auto flex-col relative h-full">
                        <ThabetSuspense>
                            {renderRoutes(routes)}
                        </ThabetSuspense>
                        {props.children}
                    </div>
                    <Footer/>
                </div>)}
        </AppContext.Consumer>
    );
}

export default withRouter(Layout);
