import React from 'react';
import IconButton from '@material-ui/core/IconButton';
// import Facebook from '@material-ui/icons/Facebook';
// import Twitter from '@material-ui/icons/Twitter';
// import Instagram from '@material-ui/icons/Instagram';
import Mail from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    return (
        <div id="footer" className="w-full h-56 border-t border-solid border-thabet-500">
            <div id="social" className="text-center pt-4">
                {/* <IconButton className="border border-solid border-teal-500 text-teal-500 mx-2" color="secondary" aria-label="add an alarm" href="https://www.facebook.com/thabetmiramas">
                    <Facebook fontSize="large" />
                </IconButton> */}
                {/* <IconButton className="border border-solid border-teal-500 text-teal-500 mx-2" color="secondary" aria-label="add an alarm">
                    <Twitter fontSize="large" />
                </IconButton>
                <IconButton className="border border-solid border-teal-500 text-teal-500 mx-2" color="secondary" aria-label="add an alarm">
                    <Instagram fontSize="large" />
                </IconButton> */}
                <IconButton className="border border-solid border-thabet-500 text-thabet-500 mx-2" href="mailto:associationthabet@gmail.com" color="secondary" aria-label="add an alarm">
                    <Mail fontSize="large" />
                </IconButton>
            </div>
            <div id="asso-info" className="text-center pt-8 text-thabet-500">
                <Typography variant="overline" display="block" gutterBottom>
                    © <b>Thabet</b> | Association caritative
                            </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                    <b>+33 6 69 45 85 95</b> | 11 RUE DE LA MAIRIE - 93290 TREMBLAY-EN-FRANCE
                            </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                    <Link to="/pages/commons/termes" className="underline">Mentions légales</Link>
                </Typography>
            </div>
        </div>
    );
}

export default Footer;
