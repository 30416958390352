import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router, BrowserRouter } from 'react-router-dom';
import history from '@history';
import store from './store';
import AppContext from './AppContext';
import routes from './configs/routesConfigs';
import { ThabetLayout } from '@thabet';
import { create } from 'jss';
import jssExtend from 'jss-extend';
import { StylesProvider, jssPreset, createGenerateClassName } from '@material-ui/styles';

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
    insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName();

const App = () => {
    return (
        <AppContext.Provider
            value={{
                routes
            }}
        >
            <StylesProvider jss={jss} generateClassName={generateClassName}>
                <Provider store={store}>
                    <Router history={history}>
                        <BrowserRouter>
                            <ThabetLayout />
                        </BrowserRouter>
                    </Router>
                </Provider>
            </StylesProvider>
        </AppContext.Provider>
    );
};

export default App;
